import React, { useState } from 'react';
import { Layout, Input, Button, Card, Typography, Spin, message } from 'antd';
import { SendOutlined, RobotOutlined, UserOutlined } from '@ant-design/icons';
import 'antd/dist/antd.css';

const { Header, Content, Footer } = Layout;
const { Text, Title, Paragraph } = Typography;
const { TextArea } = Input;

const API_ENDPOINT = 'https://7kpw93ekd4.execute-api.us-east-2.amazonaws.com/Prod/chat';

const AIChat = () => {
	const [messages, setMessages] = useState([]);
	const [input, setInput] = useState('');
	const [loading, setLoading] = useState(false);

	const handleInputChange = (e) => {
		setInput(e.target.value);
	};

	const sendMessage = async () => {
		if (!input.trim()) return;

		// Add user message to chat
		const userMessage = {
			role: 'user',
			content: input
		};

		setMessages([...messages, userMessage]);
		setLoading(true);
		setInput('');

		try {
			// Call Lambda via API Gateway
			const response = await fetch(API_ENDPOINT, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					prompt: input
				}),
			});

			if (!response.ok) {
				throw new Error(`API request failed with status ${response.status}`);
			}

			const data = await response.json();

			// OpenAI standard responses typically have a structure like data.choices[0].message
			// Assuming your Lambda returns this format
			const aiResponse = {
				role: 'assistant',
				content: data.choices && data.choices[0] && data.choices[0].message
					? data.choices[0].message.content
					: data.message || data.content || 'No response content found'
			};

			setMessages((prevMessages) => [...prevMessages, aiResponse]);
		} catch (error) {
			console.error('Error:', error);
			message.error('Failed to get a response. Please try again.');

			// Add error message to chat
			setMessages((prevMessages) => [
				...prevMessages,
				{
					role: 'assistant',
					content: 'Sorry, I encountered an error processing your request.'
				}
			]);
		} finally {
			setLoading(false);
		}
	};

	const handleKeyPress = (e) => {
		if (e.key === 'Enter' && !e.shiftKey) {
			e.preventDefault();
			sendMessage();
		}
	};

	return (
		<Layout style={{ minHeight: '100vh' }}>
			<Header style={{ background: '#001529', padding: '0 16px' }}>
				<Title level={3} style={{ color: 'white', margin: '16px 0' }}>
					<RobotOutlined /> AI Chat Assistant
				</Title>
			</Header>

			<Content style={{ padding: '24px', maxWidth: '800px', margin: '0 auto' }}>
				<div style={{ background: '#fff', padding: '24px', minHeight: '60vh', borderRadius: '8px', boxShadow: '0 2px 8px rgba(0,0,0,0.09)', marginBottom: '24px', overflowY: 'auto' }}>
					{messages.length === 0 ? (
						<div style={{ textAlign: 'center', color: '#999', marginTop: '30%' }}>
							<RobotOutlined style={{ fontSize: '48px' }} />
							<Paragraph>Send a message to start chatting with the AI</Paragraph>
						</div>
					) : (
						<div>
							{messages.map((msg, index) => (
								<Card
									key={index}
									style={{
										marginBottom: '16px',
										background: msg.role === 'user' ? '#f0f2f5' : '#fff',
										borderLeft: msg.role === 'assistant' ? '4px solid #1890ff' : 'none'
									}}
								>
									<div style={{ display: 'flex', alignItems: 'flex-start' }}>
										<div style={{
											borderRadius: '50%',
											background: msg.role === 'user' ? '#001529' : '#1890ff',
											color: 'white',
											width: '32px',
											height: '32px',
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
											marginRight: '12px'
										}}>
											{msg.role === 'user' ? <UserOutlined /> : <RobotOutlined />}
										</div>
										<div style={{ flex: 1 }}>
											<Text strong>{msg.role === 'user' ? 'You' : 'AI Assistant'}</Text>
											<Paragraph
												style={{
													whiteSpace: 'pre-wrap',
													marginTop: '4px',
													marginBottom: 0
												}}
											>
												{msg.content}
											</Paragraph>
										</div>
									</div>
								</Card>
							))}
							{loading && (
								<div style={{ textAlign: 'center', padding: '16px' }}>
									<Spin tip="AI is thinking..." />
								</div>
							)}
						</div>
					)}
				</div>

				<div style={{ display: 'flex', alignItems: 'flex-start' }}>
					<TextArea
						value={input}
						onChange={handleInputChange}
						onKeyPress={handleKeyPress}
						placeholder="Type a message..."
						autoSize={{ minRows: 2, maxRows: 6 }}
						style={{ flex: 1, marginRight: '8px' }}
						disabled={loading}
					/>
					<Button
						type="primary"
						icon={<SendOutlined />}
						onClick={sendMessage}
						disabled={!input.trim() || loading}
						style={{ height: '40px' }}
					/>
				</div>
			</Content>

			<Footer style={{ textAlign: 'center' }}>
				AI Chat Interface ©{new Date().getFullYear()}
			</Footer>
		</Layout>
	);
};

export default AIChat;
